import * as React from "react"
import { FC, useEffect, useState } from "react"
import styled from "styled-components"
import LPBanner3 from "../components/banner/LPBanner3"
import EnquiryFormModal from "../components/lead-capture/enquiry-form/EnquiryFormModal"
import LPLeadMagnetModal from "../components/lead-capture/lead-magnet/LPLeadMagnetModal"
import LPProductCarouselFullWidthBlock from "../components/products/LPProductCarouselFullWidthBlock"
import LPTitleFeatureBlock from "../components/general/LPTitleFeatureBlock"
import LPImageFeatureBlock from "../components/general/LPImageFeatureBlock"
import { colors, screenSizes } from "@social-supermarket/social-supermarket-model"
import LPCorporateQuotesBlock from "../components/reviews/LPCorporateQuotesBlock"
import LPEnquiryFormCTABlock from "../components/cta/LPEnquiryFormCTABlock"
import LPHamperFaqAccordionBlock from "../components/faq/LPHamperFaqAccordionBlock"
import LPBanner6 from "../components/banner/LPBanner6"

const mastheadListItems = [
  "Ethical hampers, desk drops and more starting at £3.25",
  "Unique corporate gift ideas – all with social impact",
  "Same-day response tailored to your brief and budget",
]

const HighlightText = styled.span`
  color: ${colors.primaryLight};
  font-weight: bold;
`
const LPTitleFeatureBlockStyled = styled(LPTitleFeatureBlock)`
  padding-top: 40px;

  @media (max-width: ${screenSizes.largeDesktop}px) {
    padding-top: 30px;
  }
`

interface Props {}

const CorporateHampersGiftBoxesLP: FC<Props> = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showLeadCaptureModal, setShowLeadCaptureModal] = useState<boolean>(false)

  useEffect(() => {
    // @ts-ignore
    if (typeof window !== "undefined" && window.HubSpotConversations?.widget) {
      // @ts-ignore
      window.HubSpotConversations.widget.load()
    }

    return () => {
      // @ts-ignore
      if (typeof window !== "undefined" && window.HubSpotConversations?.widget) {
        // @ts-ignore
        window.HubSpotConversations.widget.remove()
      }
    }
  }, [])

  return (
    <div>
      <LPBanner6
        title={"Send corporate gifts that do good"}
        actionLabel={"Get In Touch"}
        onAction={() => setShowModal(true)}
        onSecondaryAction={() => setShowLeadCaptureModal(true)}
        secondaryActionLabel={"View Brochure"}
        checklistItems={mastheadListItems}
      />
      <LPProductCarouselFullWidthBlock
        collectionName={"Hamper Landing Page"}
        showImpactArrow={false}
        hideBrand
        large
        exVat
        ignoreProductVisibility
        useImpactImage
      />
      <LPTitleFeatureBlockStyled
        title={
          "Solve social and environmental issues by purchasing from the UK's top social enterprises and mission-led businesses."
        }
        actionText={"View Brochure"}
        onClick={() => setShowLeadCaptureModal(true)}
      />
      <LPImageFeatureBlock
        title={<>Personalise your gifts</>}
        description={
          "From co-branded boxes, personalised impact booklets and greeting cards to bespoke corporate hampers and branded merch, we have a range of options to personalise your gifting programme."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/letterbox-tissue-paper-trees-season_s-greetings-scaled.jpg"
        }
        colorWay={0}
      />
      <LPImageFeatureBlock
        title={<>Automated impact reporting</>}
        description={
          "Our impact reporting and storytelling tools help you understand the impact of each purchase and improve shareholder engagement."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Untitled-design-4.png"
        }
        invert
        colorWay={1}
      />
      <LPImageFeatureBlock
        title={<>Give your recipients the choice</>}
        description={
          "Our gifting platform allows recipients to select their preferred gift and arrange delivery."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Untitled-design-6.png"
        }
        colorWay={2}
        to={"https://www.socialsupermarket.org/team-gifting-platform/"}
      />
      <LPCorporateQuotesBlock />
      <LPEnquiryFormCTABlock
        onClick={() => setShowModal(true)}
        description={
          "Speak to one of our team and we would be pleased to get you set up or answer any questions you may have."
        }
      />
      <LPHamperFaqAccordionBlock openEnquiryForm={() => setShowModal(!showModal)} />
      <LPLeadMagnetModal
        config={{
          popupTitle: "Download our Brochure",
          popupDescription: "Please provide your details to download our brochure.",
          eventName: "DownloadChristmasBrochure",
          fileUrl:
            "https://www.canva.com/design/DAGYWHYYktU/bMf5yJWp4dvQJcfGWPgKqw/view?utm_content=DAGYWHYYktU&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h2dd78a94f5",
          buttonTitle: "Download Brochure",
        }}
        show={showLeadCaptureModal}
        onClose={() => setShowLeadCaptureModal(false)}
      />
      <EnquiryFormModal
        toggle={setShowModal}
        show={showModal}
        source={"Inbound Enquiry - Gifting"}
        name={"GiftingEnquiryForm"}
      />
    </div>
  )
}

export default CorporateHampersGiftBoxesLP
