import * as React from "react"
import { graphql } from "gatsby"
import * as transformer from "../gatsby/transformers/productTaxonomyTransformer"
import * as taxonomyTransformer from "../gatsby/transformers/productTaxonomyTransformer"
import ProductTaxonomyPage from "../social-supermarket/pages/product-taxonomy/ProductTaxonomyPage"
import { filterByVisibility } from "../social-supermarket/util/productUtil"

const ProductCategoryTemplate = ({
  data: { wpProductCategory, allWpImpact, allWpCollection },
  pageContext: { products, queryId },
}) => {
  wpProductCategory.impacts = allWpImpact

  const taxonomy = transformer.fromGatsbyNoProducts(wpProductCategory, "product-category")
  taxonomy.products = filterByVisibility(products, "Marketplace")

  const collections = allWpCollection.nodes.map(node =>
    taxonomyTransformer.fromGatsby(node, "collection")
  )

  return (
    <ProductTaxonomyPage
      taxonomy={taxonomy}
      seoTitle={
        taxonomy.title
          ? `${taxonomy.title} from UK Social Enterprises`
          : `Sustainable ${taxonomy.name} from UK Social Enterprises`
      }
      title={taxonomy.title ? taxonomy.title : `Sustainable ${taxonomy.name}`}
      collectionName={`Category: ${taxonomy.name}`}
      collections={collections}
      queryId={queryId}
    />
  )
}

export default ProductCategoryTemplate

export const query = graphql`
  query ($id: String, $collectionsRegex: String) {
    allWpImpact {
      nodes {
        id
        slug
        name
      }
    }
    wpProductCategory(id: { eq: $id }) {
      id
      slug
      name
      description
      custom {
        categoryDescription
        title
        uniqueSellingPoints {
          description
        }
        showGetInTouchButton
        seoDescription
        leadCapture {
          buttonTitle
          popupTitle
          popupDescription
          fileUrl
          eventName
        }
        initialProducts
        showFilters
        subcategoriesInSidebar
        productCarouselName
        showCorporateEnquiry
        hideProductBrands
        uspFeatures {
          title
          description
          image {
            ...UrlImageFragment
          }
        }
        gatedContent {
          title
          description
          buttonTitle
          popupTitle
          popupDescription
          fileUrl
          eventName
          image {
            ...UrlImageFragment
          }
        }
      }
      wpChildren {
        nodes {
          id
          slug
          name
          image {
            ...UrlImageFragment
          }
          custom {
            title
          }
        }
      }
      wpParent {
        node {
          name
          slug
          custom {
            title
          }
          wpParent {
            node {
              name
              slug
              custom {
                title
              }
              wpParent {
                node {
                  name
                  slug
                  custom {
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpCollection(filter: { custom: { tags: { regex: $collectionsRegex } } }) {
      nodes {
        slug
        name
        description
        custom {
          oneLineDescription
          image {
            ...UrlImageFragment
          }
        }
      }
    }
  }
`
